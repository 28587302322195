import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Navigate, Route, Router, Routes } from 'react-router-dom';
import './App.css';
import Linkpage from './Linkpage/Linkpage';

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/se" replace/>} />
          <Route path="/:language/*" element={<Navigate to="/se" replace/>} />
          <Route path="/:language" element={<Linkpage/>} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
