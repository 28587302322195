import { Alert, Box, Button, Chip, Divider, Grow, Snackbar, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Instagram, Twitter, ContentCopy, CloseRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import i18n from "../i18n"
import { Navigate, useParams, Link as RouteLlink } from "react-router-dom";
import { Helmet } from "react-helmet-async"
import ReactCountryFlag from "react-country-flag"


const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #dadada;
`;

const ColumnBox = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80vw;
`;

const ProfilePhoto = styled.img`
  border-radius: 150px;
  width: 200px;
  height: 200px;
`;

const LinkButton = styled(Button)`
  border-radius: 18px !important;
  text-transform: none !important;
  width: 300px;
`;

const InfoButton = styled(Chip)`
  pointer-events: none;
  width: 100%;
  text-align: center;
  color: white;
  cursor: text !important;
`;

const Link = ({ children, text, url }: { children?: React.ReactNode, text: string, url: string }) => {
  return (
    <Box p={0.5}>
      <LinkButton variant="contained" href={ url }>
          <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          { children } <Box ml={1} mr={1}> { text }</Box> <PusherBox/>
        </Box>
      </LinkButton>
    </Box>
  )
}

const InfoBox = ({ text }: { text: string }) => {
  return (
    <Box p={0.5} style={{ width: '100%' }}>
      <InfoButton label={text} />
    </Box>
  )
}

const PusherBox = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`;

const CopyBox = ({ text, copyContent, setShowAlert, setAlertContent }: { text: string, copyContent: string, setShowAlert: React.Dispatch<boolean>, setAlertContent: React.Dispatch<string> }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyContent);
    setShowAlert(true)
    setAlertContent(`${copyContent} copied to clipboard`)
  };
  return (
    <Tooltip title={`Copy ${copyContent}`} placement="right">
      <Box p={0.5} onClick={ () => copyToClipboard() }>
        <LinkButton variant="contained" style={{ backgroundColor: '#ebcf20', color: 'black' }}>
          <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <ContentCopy/><Box > { text }</Box> <PusherBox/>
          </Box>
        </LinkButton>
      </Box>
    </Tooltip>
  )
}

const PaddedDivider = () => (<>
  <Box m={1}></Box>
  <Divider style={{ width: '100%' }} />
  <Box m={1}></Box>
</>);

const SimpleRouteLink = styled(RouteLlink)`
  text-decoration: none;
`;

const InlinePhoto = styled.img`
  max-width: 300px;
  border-radius: 30px;
`;

const Linkpage = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("alert");
  const { t, i18n } = useTranslation();
  const { language } = useParams();
  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])
  if (!language || !['se', 'en'].includes(language)) return <Navigate to="/se" replace/>;
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={ t('profile1') }
        />
        <meta property="og:title" content={ t('meta.title' )} />
        <meta property="og:image" content="https://www.okandsoldat.se/banner.jpg" />
        <meta property="og:url" content={ t('meta.url') } />
      </Helmet>
      <ContentBox>
        <ColumnBox pt={5} pb={5}>
          <ProfilePhoto src="https://pbs.twimg.com/profile_images/1527329970766290944/RgVioG1S_400x400.jpg"/>
          <ColumnBox mt={2}>
            <Typography variant="h6">{ t('name') }</Typography>
            <Typography align="center">{ t('profile1') }</Typography>
            <Typography variant="subtitle1" align="center">{ t('profile2') }</Typography>
          </ColumnBox>
          <PaddedDivider />
          <ColumnBox p={1}>
            <Typography variant="subtitle1" align="center">{ t('follow') }</Typography>
            <Link text="okandsoldat" url="https://www.instagram.com/okandsoldat/"><Instagram/></Link>
            <Link text={ t('twitterName') } url={ t('twitterLink') }><Twitter/></Link>
          </ColumnBox>
          <ColumnBox p={1}>
            <Typography variant="subtitle1" align="center">{ t('donate') }</Typography>
            <Link text="paypal.me/medicinukraine" url="https://paypal.me/medicinukraine"><PusherBox/></Link>
            {
              i18n.language === "en" ? (<>
                <CopyBox text="BIC: SWEDSESS" copyContent="SWEDSESS" {...{ setShowAlert, setAlertContent }}></CopyBox>
                <CopyBox text="IBAN: SE88 8000 0821 4997 4943 4784" copyContent="SE88 8000 0821 4997 4943 4784" {...{ setShowAlert, setAlertContent }}></CopyBox>
              </>) : (<>
                <CopyBox text="Clearingnummer: 8214-9" copyContent="8214-9" {...{ setShowAlert, setAlertContent }}></CopyBox>
                <CopyBox text="Kontonummer: 974 943 478-4" copyContent="974 943 478-4" {...{ setShowAlert, setAlertContent }}></CopyBox>
              </>)
            }
          </ColumnBox>
          <PaddedDivider />
          <ColumnBox>
            <SimpleRouteLink to={ t('altLangUrl') }>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Box mr={1}><Typography align="center">{ t('language') }</Typography></Box>
                <ReactCountryFlag svg countryCode={ t('altLangFlag') }  height="16"></ReactCountryFlag>
              </Box>
            </SimpleRouteLink>
          </ColumnBox>
        </ColumnBox>
        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={3000}
          onClose={() => setShowAlert(false)}
          >
          <Alert severity="success">
            { alertContent }
          </Alert>
        </Snackbar>
      </ContentBox>
      </>
  );
}

export default Linkpage