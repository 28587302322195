import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  se: {
    translation: {
      "name": "@okändsoldat",
      "profile1": "Efter 12 år i Försvarsmakten sa jag upp mig och nu driver jag ett ambulanslag åt Ukranian Hospitallers på fronten i striden mot Ryssland.",
      "profile2": "Vår verksamhet är helt donationsdriven.",
      "follow": "Följ mig för uppdateringar",
      "donate": "Er hjälp är ovärderlig",
      "twitterName": "okandsoldat",
      "twitterLink": "https://twitter.com/OkandSoldat",
      "language": "View page in English",
      "altLangUrl": "/en",
      "altLangFlag": "gb",
      meta: {
        "title": "Okand Soldat",
        "url": "okandsoldat.se"
      }
    }
  },
  en: {
    translation: {
      "name": "@MedicInUkraine",
      "profile1": "After 12 years in the Swedish Armed Forces I quit and now I run an ambulance team for the Ukranian Hospitallers on the front in the fight against Russia.",
      "profile2": "Our work is entirely donation driven.",
      "follow": "Follow me for updates",
      "donate": "Your help is vital ",
      "twitterName": "medicinukraine",
      "twitterLink": "https://twitter.com/medicinukraine",
      "language": "Visa sidan på Svenska",
      "altLangUrl": "/se",
      "altLangFlag": "se",
      meta: {
        "title": "Okand Soldat",
        "url": "unknownsoldier.se"
      }
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "se",
    lng: "se", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;